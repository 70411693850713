@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  max-height: 80px;
  overflow: hidden;
  border-top: 1px solid colors.$gray_secondary;
  margin-bottom: 20px;
}

.accordionHeader {
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: 90% 10%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: breakpoints.$media_l) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    //gap: 24px;
  }
}

.accordionTitle {
  font-size: 20px;
  font-family: "Now", sans-serif;
  font-weight: 500;
  color: colors.$black;

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 22px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 24px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 28px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 34px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 40px;
  }
}

.accordionTitleWhite {
  color: colors.$white;
}

.icon {
  justify-self: flex-end;
}

.accordionContent {
  color: colors.$text_dark_secondary;
  line-height: 180%;
  font-size: 16px;
  white-space: pre-wrap;
  padding-bottom: 24px;

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 22px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 26px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 32px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 36px;
  }
}

.accordionContentWhite {
  @extend .accordionContent;
  color: colors.$text_light_secondary;
}
