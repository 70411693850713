@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  @include base.padding;
  padding-bottom: 60px;
  background-color: colors.$blue;
  overflow: hidden;

  @media screen and (min-width: breakpoints.$media_l) {
    padding-top: 84px;
    padding-bottom: 84px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    flex-direction: row;
    gap: 64px;
  }
}

.titleContainer {
  @media screen and (min-width: breakpoints.$media_l) {
    padding-top: 0;
  }
}

.title {
  color: colors.$white;
}

.imageWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.satellite {
  display: block;
  max-height: 400px;
  margin-top: 48px;

  @media screen and (min-width: breakpoints.$media_l) {
    margin-top: 0;
    max-height: 600px;
  }
}
