@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  @include base.padding;
  padding-bottom: 60px;
  position: relative;
  background-color: colors.$white;

  @media screen and (min-width: breakpoints.$media_l) {
    min-height: auto;
    padding-bottom: 84px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: breakpoints.$media_s) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 64px;
  }

  @media screen and (min-width: breakpoints.$media_m) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.icon {
  height: 80px;
  margin-bottom: 20px;

  path {
    stroke: colors.$black;
  }

  @media screen and (min-width: breakpoints.$media_hd) {
    height: 90px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    height: 100px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    height: 110px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    height: 120px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    height: 130px;
  }
}

.stationIcon {
  @extend .icon;
  transform: scale(1.4);
  fill: colors.$black;

  path {
    stroke: none;
  }

  @media screen and (min-width: breakpoints.$media_hd) {
    transform: scale(1.7);
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    transform: scale(2);
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    transform: scale(2.3);
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    transform: scale(2.6);
    margin-bottom: 10px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    transform: scale(2.9);
    margin-bottom: 20px;
  }
}

.itemTitle {
  font-family: "Now", sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: colors.$text_dark_secondary;
  margin: 0;
  text-align: center;
}

.text {
  font-size: 16px;
  color: colors.$text_dark_secondary;
  margin: 0;
  line-height: 180%;
  text-align: center;
  white-space: pre-wrap;
}

.iconsContainer {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  @include base.padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  background-color: colors.$background_gray;

  @media screen and (min-width: breakpoints.$media_s) {
    gap: 48px;
    flex-direction: row;
  }

  @media screen and (min-width: breakpoints.$media_m) {
    gap: 64px;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    gap: 120px;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    gap: 160px;
  }
}

.iconItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: breakpoints.$media_m) {
    flex-direction: row;
    gap: 24px;
  }
}

.technologyItem {
  font-size: 48px;
  margin: 0;
}

.iconText {
  color: colors.$text_dark_primary;
  font-size: 14px;
  text-align: center;
  white-space: pre-wrap;

  @media screen and (min-width: breakpoints.$media_m) {
    text-align: left;
  }
}
