@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  @include base.padding;
  padding-bottom: 60px;
  background-color: colors.$white;

  @media screen and (min-width: breakpoints.$media_l) {
    padding-top: 48px;
    padding-bottom: 84px;
  }
}

.title {
  color: colors.$text_dark_primary;
}

.link {
  color: colors.$text_dark_primary;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 32px;
  gap: 32px;


  @media screen and (min-width: breakpoints.$media_m) {
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    flex-direction: row;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 64px;
  }
}

.logo {
  height: 200px;
}

.technologyTitle {
  color: colors.$text_dark_primary;
  font-family: "Now", sans-serif;
}

.technologyText {
  color: colors.$text_dark_secondary;
  line-height: 180%;
}

.image {
  width: 100%;
  max-width: 600px;
  object-fit: cover;

  @media screen and (min-width: breakpoints.$media_l) {
    display: none;
  }
}

.desktopImage {
  width: calc(100% - 64px);
  max-height: 600px;
  display: none;

  @media screen and (min-width: breakpoints.$media_l) {
    display: block;
  }
}

.text {
  color: colors.$text_dark_secondary;
  line-height: 180%;
  margin-top: -12px;

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 22px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 26px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 32px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 36px;
  }
}

.enterTitle {
  color: colors.$blue;

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 22px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 26px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 32px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 36px;
  }
}
