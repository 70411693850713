@use "src/assets/styles/colors";

.sliderOptionContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  margin-top: 24px;
}

.sliderFullWidthContainer {
  @extend .sliderOptionContainer;
  gap: 0;
}

.sliderOption {
  width: 30px;
  position: relative;
  padding-top: 20px;
  transition: 0.2s ease;
  border: none;
  cursor: pointer;
  background-color: transparent;

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: colors.$gray_secondary;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.2s ease;
  }
}

.fullWidthOption {
  @extend .sliderOption;
  width: 100%;
}

.sliderOptionSelected {
  @extend .sliderOption;
  transition: 0.2s ease;

  &::after {
    content: "";
    width: 100%;
    height: 6px;
    background-color: colors.$blue;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.2s ease;
  }
}

.fullWidthOptionSelected {
  @extend .sliderOptionSelected;
  width: 100%;
}
