@use "src/assets/styles/colors";

.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 30px 0;
}

.text {
  color: colors.$dark_blue;
  font-size: 18px;
  margin: 0;
  font-family: "Now", sans-serif;
  font-weight: 500;
}

.arrow {
  transform: rotate(-90deg);
  path {
    fill: colors.$dark_blue;
  }
}
