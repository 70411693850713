@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 64px 0;

  @media screen and (min-width: breakpoints.$media_l) {
    margin-left: -34px;
  }
}

.title {
  font-family: "Now", sans-serif;
  font-weight: 500;
  font-size: 32px;
  margin: 0;
  color: colors.$text_dark_primary;

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 40px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 48px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 56px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 76px;
  }
}

.lightTitle {
  @extend .title;
  color: colors.$white;
}

.detail {
  margin-top: 8px;
  width: 22px;
  height: 22px;

  @media screen and (min-width: breakpoints.$media_full_hd) {
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    width: 36px;
    height: 36px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    width: 48px;
    height: 48px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    width: 60px;
    height: 60px;
  }

  path {
    fill: colors.$background_primary;
  }
}

.lightDetail {
  @extend .detail;

  path {
    fill: colors.$white;
  }
}
