@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  @include base.padding;
  padding-bottom: 60px;
  background-color: colors.$blue;

  @media screen and (min-width: breakpoints.$media_m) {
    grid-column: 2;
  }
}

.content {
  max-width: 800px;

  @media screen and (min-width: breakpoints.$media_full_hd) {
    max-width: 1000px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    max-width: 1400px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    max-width: 1600px;
  }
}

.text {
  color: colors.$text_light_secondary;
  line-height: 180%;
  white-space: pre-wrap;
  margin-top: 0;

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 22px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 26px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 32px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 36px;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 24px;
}

.input {
  width: 100%;
  height: 50px;
  color: colors.$text_dark_primary;
  background-color: colors.$white;
  padding: 8px 24px;
  border: 1px solid colors.$gray_primary;
  font-family: "Now", sans-serif;
  font-size: 16px;

  &::placeholder {
    color: colors.$gray_primary;
  }

  &:focus {
    outline-color: colors.$dark_blue;
  }

  @media screen and (min-width: breakpoints.$media_hd) {
    height: 55px;
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 22px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    height: 64px;
    font-size: 26px;
  }
}

.label {
  font-size: 13px;
  color: colors.$text_dark_secondary;
}

.button {
  @extend .button;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 22px;
    height: 60px;
    padding-right: 32px;
    padding-left: 32px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    height: 70px;
    padding-right: 60px;
    padding-left: 60px;
    font-size: 26px;
  }
}

.arrow {
  transform: rotate(-90deg);
}

.title {
  color: colors.$white;
}

.policyWrapper {
  gap: 12px;
  background-color: transparent;
  border: none;
  font-family: "Now", sans-serif;
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: breakpoints.$media_hd) {
    gap: 18px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    gap: 24px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    gap: 32px;
  }
}

.policyText {
  font-size: 13px;
  color: colors.$text_light_secondary;
  line-height: 160%;
  text-align: left;

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 16px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 24px;
  }
}

.checkbox {
  width: 26px;
  height: 26px;
  position: relative;
  margin: 20px auto;
  background: #282827;
  border-radius: 50px;
  border: 1px solid colors.$gray_secondary;
  flex-shrink: 0;

  @media screen and (min-width: breakpoints.$media_2k) {
    width: 40px;
    height: 40px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    width: 56px;
    height: 56px;
  }

  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &:after {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: colors.$blue;
      background: linear-gradient(top, colors.$blue 0%, colors.$blue 100%);
      opacity: 0;
      border-radius: 50px;
      transition: 0.2s ease;

      @media screen and (min-width: breakpoints.$media_2k) {
        width: 32px;
        height: 32px;
      }
    }

    &:hover::after {
      opacity: 0.3;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;

    &:checked + label:after {
      opacity: 1;
    }
  }
}

.row {
  display: flex;
  flex-direction: column;
}

.response {
  color: #fff;
  font-weight: 500;

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 16px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 24px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 32px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 34px;
  }
}
