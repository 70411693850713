@use "src/assets/styles/breakpoints";
@use "src/assets/styles/variables";

.wrapper {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.container {
  width: 100%;
  height: calc(100vh - 24px);
  overflow: auto;
  padding: variables.$content_padding_media_s;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    padding: variables.$content_padding_media_l;
  }
}

.wrapperWithSearch {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 75px auto;
  gap: variables.$grid_gap;
  padding-top: 24px;

  @media screen and (min-width: breakpoints.$media_l) {
    padding-top: 0;
  }
}

.containerWithSearch {
  @extend .container;
  height: calc(100vh - 125px); // padding + grid + search bar height
  border-radius: 15px;
  background-color: #fff;
}
