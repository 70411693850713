@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

@font-face {
  font-family: "Now";
  src: local("Now"), url("../fonts/now.bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Now";
  src: local("Now"), url("../fonts/now.medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Now";
  src: local("Now"), url("../fonts/now.regular.woff") format("woff");
  font-weight: 400;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Now", sans-serif;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}
