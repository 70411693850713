@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  @include base.padding;
  padding-bottom: 60px;
}

.titleContainer {
  @media screen and (min-width: breakpoints.$media_m) {
    padding-bottom: 20px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: breakpoints.$media_l) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: breakpoints.$media_l) {
    gap: 24px;
  }
}

.subtitle {
  color: colors.$text_dark_primary;
  font-size: 18px;

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 24px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 28px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 34px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 40px;
  }
}

.text {
  font-weight: 500;
  color: colors.$text_dark_secondary;
  line-height: 180%;
  font-size: 16px;
}

.link {
  font-weight: 500;
  color: colors.$blue;
  line-height: 180%;
  font-size: 16px;

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 22px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 26px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 32px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 36px;
  }
}

.logo {
  margin-top: 64px;
  width: 200px;

  @media screen and (min-width: breakpoints.$media_l) {
    width: auto;
    height: 150px;
    margin-top: 0;
  }
}
