@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  display: block;
  width: 100%;
  height: 100vh;
  //background-color: colors.$blue;
  background-color: rgba(0, 94, 184, 0.98);
  color: colors.$text_light_secondary;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.4s ease;
  z-index: 80;

  @media screen and (min-width: breakpoints.$media_m) {
    display: none;
  }
}

.open {
  @extend .container;

  transform: translateX(0);
}

.close {
  @extend .container;

  transform: translateX(-100%);
}

.content {
  width: 100%;
  height: 100%;
  padding: 24px;
  @include base.column(flex-start, flex-start, 24px);
  margin-top: 168px;
}

.logo {
  position: absolute;
  top: 32px;
  left: 24px;
  width: 150px;
}

.item {
  font-weight: 500;
  color: colors.$text_light_primary;
  font-size: 26px;
  transition: 0.2s ease;

  &:hover {
    color: colors.$blue;
  }
}

.button {
  @extend .button;
  margin-top: 24px;
  max-width: 300px;
  padding: 12px 24px;
}
