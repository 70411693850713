.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    max-width: 80vw;
    white-space: nowrap;
  }
}

.big {
  padding: 40px 0;

  button {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 280px;
  }
}

.medium {
  padding: 20px 0;

  button {
    margin-top: 15px;
    width: 150px;
  }
}

.small {
  padding: 10px 0;

  button {
    margin-top: 15px;
    width: 100px;
  }
}
