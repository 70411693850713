@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: colors.$blue;
  //background-color: colors.$background_primary;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: 0;
}

.video {
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

@keyframes move {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

.background {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  animation: move 140s linear infinite;
  z-index: 0;
  object-fit: cover;

  @media screen and (min-width: breakpoints.$media_l) {
    z-index: 0;
  }
}

.planet {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%) scale(0.8);
  animation: spin 140s linear infinite;
  z-index: 0;

  @media screen and (min-width: breakpoints.$media_s) {
    transform: translate(-50%, -50%) scale(1);
  }

  @media screen and (min-width: breakpoints.$media_l) {
    transform: translate(-50%, -50%) scale(1.3);
    z-index: 0;
  }
}

.satellite {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
}

.imageWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);

  @media screen and (min-width: breakpoints.$media_l) {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.innerWrapper {
  width: 100%;
  height: 100%;

  @media screen and (min-width: breakpoints.$media_l) {
    display: grid;
    grid-template-columns: 55% 45%;
  }
}

.innerContent {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 120px;
  @include base.padding;

  @media screen and (min-width: 100px) {
    padding-bottom: 24px;
  }

  @media screen and (min-width: breakpoints.$media_l) {
    max-width: 1000px;
    justify-content: center;
    padding-bottom: 70px;
  }

  @media screen and (min-width: breakpoints.$media_xxl) {
    max-width: 1100px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 1300px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    max-width: 1500px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    max-width: 1800px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    max-width: 2400px;
  }
}

.title {
  font-size: 38px;
  line-height: 100%;
  font-family: "Now", sans-serif;
  color: colors.$white;
  margin-bottom: 30px;

  span {
    color: #d7d4d4;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
    font-size: 48px;
    white-space: pre-wrap;
  }

  @media screen and (min-width: breakpoints.$media_xxl) {
    font-size: 64px;
  }

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 70px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 104px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 104px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 124px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 136px;
  }
}

.description {
  color: colors.$text_light_secondary;
  font-size: 16px;
  line-height: 180%;

  @media screen and (min-width: breakpoints.$media_l) {
    font-size: 20px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 32px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 40px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 48px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 56px;
  }
}

.button {
  @extend .button;
  max-width: unset !important;
  width: 100% !important;
  margin-top: 50px;
  font-size: 18px;

  @media screen and (min-width: breakpoints.$media_xxl) {
    width: 300px !important;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 32px;
    height: auto !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    width: 400px !important;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    width: 500px !important;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    width: 600px !important;
    font-size: 36px;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 40px;
    width: 700px !important;
  }
}
