@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.container {
  width: 100%;
  @include base.row(space-between);
  @include base.padding;
  display: flex;
  align-items: center;
  padding-top: 12px;

  @media screen and (min-width: breakpoints.$media_l) {
    padding-bottom: 24px;
  }
}

.logo {
  height: 40px;

  @media screen and (min-width: breakpoints.$media_m) {
    height: 60px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    height: 100px;
  }
}

.itemsContainer {
  position: relative;
  @include base.row(flex-start, 24px);

  @media screen and (min-width: breakpoints.$media_l) {
    @include base.row(flex-start, 48px);
  }
}

.items {
  display: none;

  @media screen and (min-width: breakpoints.$media_m) {
    @include base.row(flex-start, 24px);
  }

  @media screen and (min-width: breakpoints.$media_l) {
    @include base.row(flex-start, 48px);
  }
}

.darkMenuItem {
  &:hover {
    color: colors.$white !important;
  }
}

.darkItem {
  color: colors.$text_dark_primary;
  font-size: 14px;
  transition: 0.2s ease;

  &:hover {
    color: colors.$text_dark_secondary;
    transform: translateY(-3px);
  }
}

.menuItem {
  color: colors.$white;
  font-size: 14px;
  transition: 0.2s ease;

  &:hover {
    color: colors.$background_primary;
    transform: translateY(-3px);
  }

  @media screen and (min-width: breakpoints.$media_m) {
    font-size: 16px;
  }

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 24px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 36px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 40px;
  }
}

.menuItemDark {
  @extend .menuItem;

  color: colors.$text_dark_secondary;

  &:hover {
    color: colors.$text_dark_primary;
    transform: translateY(-3px);
  }
}

.button {
  @extend .button;
  display: none;

  @media screen and (min-width: breakpoints.$media_m) {
    display: block;
  }
}
