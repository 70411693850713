@use "src/assets/styles/base";
@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 24px 60px 24px;
  justify-content: center;
  margin: auto;

  @media screen and (min-width: breakpoints.$media_l) {
    max-width: 1000px;
    justify-content: center;
    padding-bottom: 70px;
  }

  @media screen and (min-width: breakpoints.$media_xxl) {
    max-width: 1100px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    max-width: 1500px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    max-width: 1800px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    max-width: 2400px;
  }
}

.text {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 12px 0 48px 0;

  @media screen and (min-width: breakpoints.$media_m) {
    font-size: 18px;
  }

  @media screen and (min-width: breakpoints.$media_xl) {
   margin: 24px 0 48px 0;
  }

  @media screen and (min-width: breakpoints.$media_hd) {
    font-size: 20px;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 22px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    font-size: 28px;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    font-size: 30px;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 32px;
  }
}

.redLogo {
  width: 60%;
  margin: 48px auto 24px auto;

  @media screen and (min-width: breakpoints.$media_l) {
    width: 40%;
    margin: 48px auto;
  }
}

.fengParpDesktop {
  display: none;

  @media screen and (min-width: breakpoints.$media_m) {
    display: block;
  }
}

.fengParpMobile {
  display: block;

  @media screen and (min-width: breakpoints.$media_m) {
    display: none;
  }
}

.mainButton {
  @extend .button;
  width: 100% !important;
  max-width: 300px;
  line-height: 1.5;
  font-size: 18px;
  margin: auto;

  @media screen and (min-width: breakpoints.$media_xxl) {
    width: 300px !important;
  }

  @media screen and (min-width: breakpoints.$media_full_hd) {
    font-size: 24px;
    height: auto !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    width: 100%;
    max-width: 400px;
  }

  @media screen and (min-width: breakpoints.$media_2k) {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    width: 500px !important;
  }

  @media screen and (min-width: breakpoints.$media_quad_hd) {
    width: 600px !important;
    font-size: 36px;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  @media screen and (min-width: breakpoints.$media_4k) {
    font-size: 40px;
    width: 700px !important;
  }
}

.parpLogo {
  width: 130px;
  margin: 24px auto;

  @media screen and (min-width: breakpoints.$media_xs) {
    width: 150px;
  }

  @media screen and (min-width: breakpoints.$media_s) {
    width: 190px;
  }

  @media screen and (min-width: breakpoints.$media_m) {
    display: none;
  }
}
